import React, { useState, useEffect, useCallback } from "react";
import Nav from "../Nav";
import ListCard from "./ListCard";
import RatingBox from "./RatingBox";
import SkeltonLoader from "./SkeltonLoader";
export default function ShowNps(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [currentService, setCurrentService] = useState({
    label: "",
    value: "",
  });
  const [dayWiseNps, setDayWiseNps] = useState([]);
  const [stars, setStars] = useState([]);
  const [surveyID, setSurveyID] = useState(
    props.params.nps_survey_id ? props.params.nps_survey_id : null
  );

  const fetchMyAPI = useCallback(async () => {
    let url = `/dashboards/get_survey_by_id${location.search}`;
    let response = await fetch(url);
    response = await response.json();
    setIsLoading(false);
    if (response.success) {
      setDayWiseNps(response.data);
      setStars(response.survey_stars);
      setCurrentService({
        value: response.service_id,
        label: response.service_name,
      });
    }
    console.log(response);
  }, [surveyID]);

  const goBack = (open) => {
    // history.back()
    if (history.state == null) {
      history.pushState({}, "", "/");
      location.reload();
    } else {
      history.back();
    }
  };
  const starMarkUpdat = (isMarked, id) => {
    if (isMarked) {
      setStars([...stars, id]);
    } else {
      setStars(stars.filter((item) => item !== id));
    }
  };
  useEffect(() => {
    setIsLoading(true);
    let Id = location.search.split("=")[1];
    setSurveyID(Id);
    if (surveyID || Id) {
      setCurrentService({ label: "", value: "" });
      fetchMyAPI();
    }
    return () => {};
  }, [fetchMyAPI]);

  return (
    <React.Fragment>
      <div className="container-fluid">
          <div className="MainPage">
            <div className="nps-report">
              <Nav
                {...props}
                title={"NPS Report"}
                showFilterIcon={false}
                sowSearchIcon={false}
                backPrassArrow={true}
                goBack={goBack}
                surveyID={surveyID}
              ></Nav>
            </div>
            <div className="">
              <RatingBox
                name={`${currentService.value}-${currentService.label}`}
                averageRating={{
                  rating: 0.0,
                  nps: 0,
                  total_survey: 0,
                }}
                boxDisplay={false}
              />
            </div>
            {isLoading && (
              <div className="MainPage">
                <div className="MainPage">
                  <SkeltonLoader items={[1, 2]} both = {true} lazyLoader={false}/>
                </div>
              </div>
            )}
            <ListCard
              dayWiseNps={dayWiseNps}
              api={props.api}
              starMarkUpdat={starMarkUpdat}
              stars={stars}
            ></ListCard>
          </div>
      </div>
    </React.Fragment>
  );
}
