import React from "react";
import "../Common.css";
import "./ListCard.css";
import Rating from "@material-ui/lab/Rating";
import { WhatsappShareButton } from "react-share";
export default function InformationModal({
  survey,
  busDetails,
  api,
  markStar,
  stars,
}) {
  const shareButton = async () => {
    try {
      await navigator.share({
        title: survey.user_name,
        url: `/dashboards/show_nps?nps_survey_id=${survey.id}`,
      });
      console.log("Data was shared successfully");
      console.log(`/dashboards/show_nps?nps_survey_id=${survey.id}`);
    } catch (err) {
      let x = "User-agent header sent: " + navigator.userAgent;
      // alert(err.message)
      // alert(x)
      console.error("Share failed:", err.message);
    }
  };
  return (
    <React.Fragment>
      <div className="modal" id="infoModal">
        <div className="modal-dialog">
          <div className="modal-content border-0">
            <div className="modal-header pb-5">
              <h4 className="modal-title fs-1 black-text">NPS Report info</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
              {stars.includes(survey.id) ? (
                  <div style={{left: 35,top: 55, position: 'absolute',zIndex: 1}}>
                    <Rating
                      max={1}
                      name="rating"
                      value={1}
                      onChange={(event, value) => markStar(survey)}
                    />
                  </div>
                ) : (
                  <div style={{left: 35,top: 55, position: 'absolute',zIndex: 1}}>
                    <Rating
                      max={1}
                      name="rating"
                      value={null}
                      onChange={(even,value) => markStar(survey)}
                    />
                  </div>
                )}
              <p className="m-0 mdlIcons" style={{}}>
              <img
                src="https://images.railyatri.in/ry_images_prod/whatsapp-icon-1618855231.png"
                alt="whatsapp icon"
                className=""
                style= {{visibility: 'hidden',zIndex: -1 }}
                />
                <WhatsappShareButton
                  url={`/dashboards/show_nps?nps_survey_id=${survey.id}`}
                  title={survey.user_name}
                >
                  <img
                    src="https://images.railyatri.in/ry_images_prod/whatsapp-icon-1618855231.png"
                    alt="whatsapp icon"
                    className=""
                  />
                </WhatsappShareButton>
                <img
                  src="https://images.railyatri.in/ry_images_prod/share-icon-1618855217.png"
                  alt="share icon"
                  className=""
                  onClick={shareButton}
                />
              </p>
            </div>
            <div className="modal-body">
              <div className="paxData">
                <p className="m-0 fw-500 black-txt fs-0">
                  {survey.user_name} [{survey.trip_size}]
                </p>
                <p className="black-txt fs-0">
                  <span>{survey.user_mobile}</span> |{" "}
                  <span>{survey.user_email}</span>
                </p>
              </div>
              <div className="jrnyData">
                <p className="fs12 m-0 gry75-text">Journey Details</p>
                <p className="fs-0 m-0 black-text">{survey.pnr}</p>
                <p className="fs-0 black-text">
                  <span>
                    {survey.from_city} - {survey.to_city}
                  </span>{" "}
                  | <span>{survey.doj}</span>
                </p>
              </div>
              <div className="row bookData">
                <div className="col-4 pl-0 mb-1 fs-0 gry75-text">
                  Booking at:
                </div>
                <div className="col-8 p-0 mb-1 fs-1 black-text">
                  {survey.booking_created_at}
                </div>

                <div className="col-4 pl-0 mb-1 fs-0 gry75-text">Seats:</div>
                <div className="col-8 p-0 mb-1 fs-1 black-text">
                  {survey.seats}
                </div>

                <div className="col-4 pl-0 mb-1 fs-0 gry75-text">Boarding:</div>
                <div className="col-8 p-0 mb-1 fs-1 black-text">
                  {survey.boarding_point}
                </div>

                <div className="col-4 pl-0 mb-3 fs-0 gry75-text">Bropping:</div>
                <div className="col-8 p-0 mb-3 fs-1 black-text">
                  {survey.dropping_point}
                </div>

                <div className="col-4 pl-0 mb-3 fs-0 gry75-text">Booking:</div>
                <div className="col-8 p-0 mb-3 fs-1 black-text">
                  {survey.booking_source_title}
                </div>

                <div className="col-4 pl-0 mb-3 fs-0 gry75-text">NPS:</div>
                <div className="col-8 p-0 mb-3 black-text">
                  {survey.rating <= 4 && (
                    <span className="fs-0 NPS_rat rtngOrg">
                      {survey.rating}
                    </span>
                  )}
                  {survey.rating > 4 && survey.rating <= 7 && (
                    <span className="fs-0 NPS_rat rtngYlw">
                      {survey.rating}
                    </span>
                  )}
                  {survey.rating > 7 && (
                    <span className="fs-0 NPS_rat rtngGrn">
                      {survey.rating}
                    </span>
                  )}
                  <span className="fs12">
                    {survey.nps_source} | {survey.date}
                  </span>
                </div>
              </div>

              <div className="row busData">
                {/* <div className="col-5 pl-0 mb-1 fs-0 gry75-text">
                  Route Manager:
                </div>
                <div className="col-7 p-0 mb-1 fs-1 black-text">
                  Prabhav Mishra
                </div> */}

                <div className="col-5 pl-0 mb-1 fs-0 gry75-text">
                  Departing Captain:
                </div>
                <div className="col-7 p-0 mb-1 fs-1 black-text">
                  {busDetails.departure_captain}
                </div>

                <div className="col-5 pl-0 mb-1 fs-0 gry75-text">
                  Arriving Captain:
                </div>
                <div className="col-7 p-0 mb-1 fs-1 black-text">
                  {busDetails.arrival_captain}
                </div>

                <div className="col-5 pl-0 mb-1 fs-0 gry75-text">
                  Resolution:
                </div>
                {survey.resolution_comment &&
                  survey.resolution_comment.length > 0 && (
                    <div className="col-7 p-0 mb-1 fs-1 black-text npsRsln">
                      {/* <p className="fs12 black-text">Prabhav Mishra:</p> */}
                      <p className="fs-0 black-text">
                        {survey.resolution_comment}
                      </p>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
