import React, { lazy, Suspense, useState, useEffect } from "react";
const Nav = lazy(() => import("../Nav"));
const AutocompleteList = lazy(() => import("./AutocompleteList"));
const Alerts = lazy(() => import("../Alerts"));
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import SkeltonLoader from "./SkeltonLoader";
import "../Common.css";
import "./ListCard.css";
import "./ZoneWiseNps.css";
export default function ZoneWiseNps(props) {
  let history = props.useHistory();
  let Link = props.Link;
  const [currentZone, setCurrentZone] = useState({});
  const [zones, setZones] = useState([]);
  const [filterText, setFilterText] = useState('')
  const [services, setServices] = useState([]);
  const [currentRating, setCurrentRating] = useState({start:1, end:10})
  const [filterServices, setFilterServices] = useState([]);
  const [alerts, setAlerts] = useState({ type: "", text: "", any: false });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingZone, setIsLoadingZone] = useState(false);
  const renderLoader = () => (
    <div>
      <SkeltonLoader items={[1, 2]} lazyLoader={true} both={false} />
    </div>
  );
  const handleChange = (zone) => {
    console.log(zone);
    setCurrentZone(zone);
  };
  const fatchApi = async () => {
    try {
      setIsLoading(true);
      let response = await fetch(
        `/dashboards/zone_wise_services?zone_id=${currentZone.value}`
      );
      response = await response.json();
      if (response.success) {
        console.log(response);
        setServices(response.services);
        setFilterText(response.filter_text)
        let rating= response.services.filter(item => item.avg_rating>=currentRating.start && Math.floor(item.avg_rating)<=currentRating.end);
        setFilterServices(rating)
        setIsLoading(false);
        if(response.services.length===0){
          setAlerts({type: 'warning',text: 'No Service found',any: true })
        }else{
          setAlerts({type: 'success',text: `${response.services.length} Services found`,any: true })
        }
      }
    } catch (error) {
      setIsLoading(false);
      setAlerts({ type: "error", text: "Something went wrong.", any: true });
    }
    
  };
  const goBack = (open) => {
    window.history.back();
  };
  const updateImage = (element)=> {
    let datasrc = element.children[0].dataset.src
    let src = element.children[0].src
    element.children[0].dataset.src = src;
    element.children[0].src = datasrc;
  };
  const ratingFilter = (start,end,element) => {
    updateImage(element)
    let item = document.getElementsByClassName('smilyActR').item(i=>i)
    if (item && item !== element){
      item.classList.remove('smilyActR')
      updateImage(item)
    }
    let hasClass = element.classList.contains('smilyActR')
    if(hasClass){
      element.classList.remove('smilyActR');
      setCurrentRating({start: 1,end: 10});
      let rating= services.filter(item => item.avg_rating>=1 && Math.floor(item.avg_rating)<=10);
      setFilterServices(rating)
    }else{
      element.classList.add('smilyActR');
      setCurrentRating({start: start,end:end});
      let rating= services.filter(item => item.avg_rating>=start && Math.floor(item.avg_rating)<=end);
      setFilterServices(rating)
    }
    if(filterServices.length===0){
      setAlerts({type: 'warning',text: 'No Service found',any: true })
    }
    // else{
    //   setAlerts({type: 'success',text: `${filterServices.length} Services found`,any: true })
    // }
  };
  const getZones = async () => {
    try {
      setIsLoadingZone(true)
      let response = await fetch("/dashboards/zone_wise_nps.json");
      response = await response.json();
      if (response.success) {
        setIsLoadingZone(false)
        setZones(response.zones);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (props.params.zones) {
      setZones(props.params.zones);
    } else {
      getZones();
    }
    if (currentZone.value) {
      fatchApi();
    }
    return () => {};
  }, [currentZone.value]);
  return (
    <React.Fragment>
      <div className="container-fluid">
        <Suspense fallback={renderLoader()}>
          <div className="MainPage">
            <div className="nps-report">
              <Nav
                {...props}
                title={"Zone-Wise NPS"}
                showFilterIcon={false}
                sowSearchIcon={false}
                backPrassArrow={true}
                goBack = {goBack}
                showLink={true}
                path={'/'}
                linkText = "NPS Service"
              ></Nav>
              <div className="row intc-bg">
                <AutocompleteList
                  auto_complete_data={zones}
                  handleChange={handleChange}
                  currentService={currentZone}
                  history={history}
                  isReplace = {false}
                  isLoading={isLoadingZone}
                  placeholder="Please select a zone.."
                ></AutocompleteList>
              </div>

              <div className="row">
                <div className="col-4 ratingWrap">
                  <div className="col-12 text-center ratingCard bad" onClick={(event)=> {ratingFilter(1,6,event.currentTarget)}}>
                    <img
                      src="https://images.railyatri.in/ry_images_prod/sadInactive-1620289296.png"
                      className="smilySect"
                      data-src="https://images.railyatri.in/ry_images_prod/sadactive-1620289291.png"
                    />
                    <p className="black-text m-0 fs12 fw-500">Rating 1-6</p>
                  </div>
                </div>
                <div className="col-4 ratingWrap">
                  <div className="col-12 text-center ratingCard aver" onClick={(event)=>{ratingFilter(7,8,event.currentTarget)}}>
                    <img
                      src="https://images.railyatri.in/ry_images_prod/avgInactive-1620289285.png"
                      className="smilySect smilyDis"
                      data-src="https://images.railyatri.in/ry_images_prod/avgactive-1620289274.png"
                    />
                    <p className="black-text m-0 fs12">Rating 7-8</p>
                  </div>
                </div>
                <div className="col-4 ratingWrap" >
                  <div className="col-12 text-center ratingCard good" onClick={(event)=>{ratingFilter(9,10,event.currentTarget)}}>
                    <img
                      src="https://images.railyatri.in/ry_images_prod/smileInactive-1620289308.png"
                      className="smilySect smilyDis"
                      data-src="https://images.railyatri.in/ry_images_prod/smileactive-1620289302.png"
                    />
                    <p className="black-text m-0 fs12">Rating 9-10</p>
                  </div>
                </div>
              </div>
              {isLoading && (
              <div className="MainPage">
                <div className="MainPage">
                  <SkeltonLoader items={[1,2,3,4,5,6]} both = {true} lazyLoader={true}/>
                </div>
              </div>
              )}
              {!isLoading && <div>
                <p className="gry75-text fs-0 font-italic text-center zoneTitle">
                  {filterText}
                </p>
                {filterServices.map((service) => (
                  <div className="zoneNps pos-rel" key={service.service_id}>
                    <Link to={`/?service_id=${service.service_id}&zone_id=${currentZone.value}`}>
                      <Card>
                        {Math.floor(service.avg_rating) < 7 && (
                          <div className="ratingCnt fw-500 fs-1 rtngOrg">
                            <span>{service.avg_rating}</span>
                          </div>
                        )}
                        {Math.floor(service.avg_rating) >= 7 && Math.floor(service.avg_rating) <= 8 && (
                          <div className="ratingCnt fw-500 fs-1 rtngYlw">
                            <span>{service.avg_rating}</span>
                          </div>
                        )}
                        {service.avg_rating >= 9 && service.avg_rating <= 10 && (
                          <div className="ratingCnt fw-500 fs-1 rtngGrn">
                            <span>{service.avg_rating}</span>
                          </div>
                        )}
                        <CardContent>
                          <p className="m0 serName">{service.service_id}-{service.service_name}</p>
                        </CardContent>
                      </Card>
                    </Link>
                  </div>
                ))}
              </div>}
            </div>
          </div>
          <Alerts alerts={alerts} setAlerts={setAlerts}></Alerts>
        </Suspense>
      </div>
    </React.Fragment>
  );
}
