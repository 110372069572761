import React from "react";
import '../Common.css';
import './RatingBox.css';

export default function RatingBox({name,averageRating,boxDisplay,filterText}) {
  return (
    <React.Fragment>
      {boxDisplay &&<div className="row">
        <div className="col-4 ratingWrap">
          <div className="col-12 text-center ratingCard">
            <p className="black-text fs-2 m-0 fw-500">{averageRating.rating}</p>
            <p className="black-text m-0 fs12">Rating</p>
          </div>
        </div>
        <div className="col-4 ratingWrap">
          <div className="col-12 text-center ratingCard">
            <p className="black-text fs-2 m-0 fw-500">{averageRating.nps}</p>
            <p className="black-text m-0 fs12">NPS</p>
          </div>
        </div>
        <div className="col-4 ratingWrap">
          <div className="col-12 text-center ratingCard">
            <p className="black-text fs-2 m-0 fw-500">{averageRating.total_survey}</p>
            <p className="black-text m-0 fs12">Respondents</p>
          </div>
        </div>
      </div>}
      <div className="row">
        <div className="col-12 slctList_summ">
          <p className="m-0 black-txt fs-0">{name}</p>
        </div>
      </div>
      {filterText && <div className="row">
        <div className="col-12 text-center listCard_noNPS">
          <em className="fs-0">Showing data for {filterText}</em>
        </div>
      </div>}
    </React.Fragment>
  );
}
