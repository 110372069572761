import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from '@material-ui/core/Backdrop';
const useStyles = makeStyles((theme) =>({
  dWith: {
    width: "20.5rem",
  },
  cardPad: {
    marginTop: 10,
    marginBottom: 10,
  },
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
export default function SkeltonLoader({ items,lazyLoader,both }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(lazyLoader);
  const handleClose = () => {
    // setOpen(false);
  };
  const handleToggle = () => {
    // setOpen(!open);
  };
  return (
    <React.Fragment>
      {(lazyLoader || both) &&
        <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
          <CircularProgress color="inherit" />
        </Backdrop>
      }
      {(!lazyLoader || both) && <div>
        {items.map((i) => (
          <Card key={`skelton-${i}`} className={classes.cardPad}>
            <CardContent>
              <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                <Skeleton variant="rect" />
              </div>
              <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                <Skeleton variant="rect" />
              </div>
              <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                <Skeleton variant="rect" />
              </div>
            </CardContent>
          </Card>
        ))}
      </div>}
    </React.Fragment>
  );
}
