import React, { useState, useEffect, useCallback, Suspense, lazy } from "react";
const Nav = lazy(() => import("../Nav"));
const FilterPage = lazy(() => import("./FilterPage"));
const AutocompleteList = lazy(() => import("./AutocompleteList"));
const ListCard = lazy(() => import("./ListCard"));
const RatingBox = lazy(() => import("./RatingBox"));
const Alerts = lazy(() => import("../Alerts"));
import SkeltonLoader from "./SkeltonLoader";
import queryString from "query-string";
export default function Index(props) {
  const renderLoader = () => (
    <div>
      <SkeltonLoader items={[1, 2]} lazyLoader={true} both={false} />
    </div>
  );
  let history = props.useHistory();
  const parsed = queryString.parse(location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingZone, setIsLoadingZone] = useState(false);
  const [currentService, setCurrentService] = useState({});
  const [services, setServices] = useState([]);
  const [bookingSource, setBookingSource] = useState([]);
  const [dayWiseNps, setDayWiseNps] = useState([]);
  const [surveys, setSurveys] = useState({});
  const [stars, setStars] = useState([]);
  const [alerts, setAlerts] = useState({ type: "", text: "", any: false });
  const [filterData, setFilterData] = useState({
    start_date: null,
    end_date: null,
    booking_source: null,
    rating: "0,10",
    selectedOption: null,
    is_star: false,
  });
  const [openfilter, setOpenfilter] = useState(false);
  const [surveyID, setSurveyID] = useState("");
  const [filterText, setFilterText] = useState(null);
  const [averageRating, setAverageRating] = useState({
    rating: 0.0,
    nps: 0,
    total_survey: 0,
  });
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const fetchMyAPI = useCallback(
    async (
      options = {
        start_date: null,
        end_date: null,
        booking_source: null,
        rating: "",
        is_star: false,
      }
    ) => {
      let queryParams = Object.entries(options)
        .map((v, i) => v.join("="))
        .join("&");
        if(parsed.zone_id){
          queryParams = `${queryParams}&zone_id=${parsed.zone_id}`
        }
      try {
        let response = await fetch(
          `/dashboards/nps_report?service_id=${currentService.value}&${queryParams}`
        );
        response = await response.json();
        setIsLoading(false);
        if (response.success) {
          setSurveys(response);
          setDayWiseNps(response.data);
          setStars(response.survey_stars);
          setFilterText(response.filter_text);
          setAverageRating(() => {
            return {
              nps: response.nps,
              rating: response.avg_rating,
              total_survey: response.surveys_found,
            };
          });
          setAlerts({
            text: `${response.surveys_found} surveys found.`, 
            type: `${response.surveys_found > 0 ? "success" : "warning"}`,
            any: true,
          });
          // history.push(`${response.filter_url}`)
        } else {
          setAlerts({ text: response.message, type: "error", any: true });
        }
        console.log(response);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setAlerts({ text: "Something went wrong", type: "error", any: true });
      }
    },
    [currentService.value]
  );
  const starMarkUpdat = (isMarked, id) => {
    if (isMarked) {
      setStars([...stars, id]);
    } else {
      setStars(stars.filter((item) => item !== id));
    }
  };
  const onClickOpenFilter = (open) => {
    if (currentService.value) {
      setOpenfilter(open);
    } else {
      setAlerts({ text: "Please Select a Service", type: "error", any: true });
    }
  };

  const handleChange = (service) => {
    setCurrentService(service);
  };

  const handleFilteroptions = (options) => {
    setIsLoading(true);
    setFilterData((prevFilterData) => ({ ...prevFilterData, ...options }));
    if (currentService.value) {
      fetchMyAPI(options);
    } else {
      setAlerts({ text: "Please Select a Service", type: "error", any: true });
    }
  };

  const getNpsByID = (event) => {
    console.log(surveyID);
    event.preventDefault();
    document.getElementById("search_submit").style.zIndex = 2;
    if (surveyID) {
      history.push(`/dashboards/show_nps?nps_survey_id=${surveyID}`);
    }
  };
  const handleSearchById = (value) => {
    setSurveyID(value);
  };
  const getServicesBookingSource = async () => {
    setIsLoadingZone(true)
    let response = await fetch("/dashboards.json");
    response = await response.json();
    setIsLoadingZone(false)
    setServices(response.services);
    setBookingSource(response.booking_source);
    if (parsed.service_id) {
      let service = response.services.find(
        (itm) => itm.value == parseInt(parsed.service_id)
      );
      setCurrentService(service);
    }
  };
  useEffect(() => {
    if (props.params.services) {
      setServices(props.params.services);
      setBookingSource(props.params.booking_source);
    } else {
      getServicesBookingSource();
    }
    if (parsed.service_id && props.params.services) {
      let service = props.params.services.find(
        (itm) => itm.value == parseInt(parsed.service_id)
      );
      setCurrentService(service);
    }
    if (currentService.value) {
      setIsLoading(true);
      fetchMyAPI(filterData);
    }

    return () => {};
  }, [fetchMyAPI]);

  return (
    <React.Fragment>
      <div className="container-fluid">
        <Suspense fallback={renderLoader()}>
          {!openfilter && (
            <div className="MainPage">
              <div className="nps-report">
                <Nav
                  {...props}
                  title={"NPS Report"}
                  showFilterIcon={true}
                  sowSearchIcon={true}
                  onClickOpenFilter={onClickOpenFilter}
                  isFilterApplied={isFilterApplied}
                  getNpsByID={getNpsByID}
                  backPrassArrow={false}
                  surveyID={surveyID}
                  handleSearchById={handleSearchById}
                  Link={props.Link}
                  showLink={true}
                  path={'/dashboards/zone_wise_nps'}
                  linkText = "NPS Zone"
                ></Nav>
                <div className="row intc-bg">
                  <AutocompleteList
                    auto_complete_data={services}
                    handleChange={handleChange}
                    currentService={currentService}
                    history={history}
                    isReplace={TextTrackCue}
                    isLoading={isLoadingZone}
                    placeholder="Please select a service.."
                  ></AutocompleteList>
                </div>
              </div>
              <div className="">
                {!isLoading && (
                  <RatingBox
                    name={currentService.label}
                    averageRating={averageRating}
                    boxDisplay={true}
                    filterText={filterText}
                  />
                )}
                {isLoading && (
                  <RatingBox
                    name={""}
                    averageRating={{
                      rating: 0.0,
                      nps: 0,
                      total_survey: 0,
                    }}
                    boxDisplay={true}
                    filterText={""}
                  />
                )}
              </div>
              {isLoading && (
                <div className="MainPage">
                  <SkeltonLoader
                    items={[1, 2, 3, 4, 5, 6]}
                    both={true}
                    lazyLoader={true}
                  />
                </div>
              )}
              {!isLoading && (
                <ListCard
                  dayWiseNps={dayWiseNps}
                  api={props.api}
                  starMarkUpdat={starMarkUpdat}
                  stars={stars}
                  setAlerts={setAlerts}
                />
              )}
            </div>
          )}
          {openfilter && (
            <div className="FilterPage">
              <FilterPage
                {...props}
                bookingSource={bookingSource}
                onClickOpenFilter={onClickOpenFilter}
                handleFilteroptions={handleFilteroptions}
                filterData={filterData}
                setIsFilterApplied={setIsFilterApplied}
              ></FilterPage>
            </div>
          )}
          <Alerts alerts={alerts} setAlerts={setAlerts}></Alerts>
        </Suspense>
      </div>
    </React.Fragment>
  );
}
